import cc from 'classcat';
import { Flags } from '../input/flags';

interface Props {
	name?: Flags;
	size?: string;
	className?: string;
}

const Flag = (props: Props) => {
	const { name, size = '24', className } = props;

	return (
		<img
			className={cc(['w-6', className])}
			src={`/img/flags/${name}.svg`}
			width={size}
			height={size}
			alt="flag"
			loading="lazy"
		/>
	);
};

export default Flag;
