export const sortElementsByList = (
	elements: Array<unknown>,
	priorityList: Array<unknown>,
	comparisonKey: string,
): Array<unknown> => {
	const sortedElements = [];
	const skipSet = new Set();

	priorityList.forEach((priorityElement) => {
		const element = elements.find(
			(element) => element[comparisonKey] === priorityElement,
		);

		sortedElements.push(element);
		skipSet.add(priorityElement);
	});

	elements.forEach((element) => {
		if (!skipSet.has(element[comparisonKey])) {
			sortedElements.push(element);
		}
	});

	return sortedElements;
};
