import { forwardRef, useEffect, useRef, useState } from 'react';
import {
	FieldValues,
	RefCallBack,
	UseFormSetFocus,
	UseFormSetValue,
} from 'react-hook-form';
import parsePhoneNumber, {
	CountryCode,
	getCountries,
	getCountryCallingCode,
} from 'libphonenumber-js';
import cc from 'classcat';

import Icon from '../icon';
import Flag from '../flag';
import { Flags } from './flags';
import { sortElementsByList } from '../../utils/sorting';
import { useClickOutside } from '../../utils/hooks/useClickOutside';
import { TextParagraph, TextParagraphSize } from '../typography/typography';
import * as React from 'react';
import {
	formatNumber,
	stripCharacters,
} from '@vintro/components/utils/formatters';

import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import XIcon from '@heroicons/react/outline/XIcon';

const COUNTRIES = getCountries().map((countryCode) => {
	// @ts-expect-error DisplayNames will throw warnings until v4.5 https://github.com/microsoft/TypeScript/pull/44022
	const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

	return {
		code: countryCode,
		callingCode: getCountryCallingCode(countryCode),
		name: regionNames.of(countryCode),
		showFlag: countryCode.toLowerCase() in Flags,
	};
});

interface IconOption {
	stroke?: string;
	color?: string;
}

interface IconOptions {
	static?: IconOption;
	focus?: IconOption;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	ref?: RefCallBack;
	locale?: string;
}

interface TextInputProps {
	id?: string;
	label: string;
	className?: string;
	errorMessage?: string;
	acceptZeroDecimal?: boolean;
	labelProps?: React.InputHTMLAttributes<HTMLLabelElement>;
	inputProps: InputProps;
	icon?: string;
	iconOptions?: IconOptions;
	trailingAddOnText?: string | string[];
	hideLabel?: boolean;
	truncateLabel?: boolean;
	prefix?: string;
	postfix?: string;
	onChange?: (value) => void;
	setValue?: UseFormSetValue<FieldValues>;
	onRemove?: (value) => void;
}

interface TextAreaInputProps {
	label?: string;
	truncateLabel?: boolean;
	className?: string;
	ref: React.MutableRefObject<HTMLInputElement>;
	errorMessage?: string;
	inputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
	icon?: string;
	iconOptions?: IconOptions;
}

interface PhoneInputProps extends TextInputProps {
	defaultCountry?: string;
	countriesSortOrder: Array<string>;
	dropdownHeight?: string;
	onCountryChange?: React.Dispatch<React.SetStateAction<string>>;
	setFocus?: UseFormSetFocus<FieldValues>;
}

export const TextInput = (props: TextInputProps) => {
	const {
		id,
		label,
		acceptZeroDecimal = false,
		hideLabel = false,
		truncateLabel = true,
		errorMessage,
		labelProps = {},
		inputProps,
		icon,
		iconOptions,
		trailingAddOnText,
		prefix,
		postfix,
		className: containerClassName,
		setValue,
		onRemove = null,
	} = props;
	const {
		className,
		ref: localRef,
		name,
		type,
		required,
		...restInputProps
	} = inputProps;
	const { className: labelClassName, ...restLabelProps } = labelProps;

	const [showPassword, setShowPassword] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [formattedValue, setFormattedValue] = useState('');
	const inputValue = inputProps.value ? inputProps.value : undefined;

	const internalRef = useRef(null);

	useEffect(() => {
		// Local ref is loaded based on FAQ on react hook forms https://react-hook-form.com/faqs
		// Is necessary to load the mask when values are loaded by default
		if (inputProps.locale && internalRef.current.value) {
			const formattedNumber = formatNumber(
				internalRef.current.value,
				inputProps.locale,
				acceptZeroDecimal,
			);

			setFormattedValue(formattedNumber);
		}
	}, []);

	const isPassword = type === 'password';
	const isEmailWithPrefilledDomain = type === 'email' && !!trailingAddOnText;
	// we change the type to text when we click the show password icon
	const inputType =
		(isPassword && showPassword) || isEmailWithPrefilledDomain ? 'text' : type;
	const showRightIcons = isPassword || errorMessage || postfix;

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleIsFocused = () => {
		setIsFocused(!isFocused);
	};

	const formatMaskNumber = (e) => {
		setIsFocused(!isFocused);

		if (inputProps.locale) {
			const rawNumber = stripCharacters(e.target.value);
			const formattedNumber = formatNumber(
				rawNumber,
				inputProps.locale,
				acceptZeroDecimal,
			);
			setFormattedValue(e.target.value.length === 0 ? '' : formattedNumber);
			setValue && setValue(name, rawNumber);
		}

		inputProps.onChange && inputProps.onChange(e);
	};

	return (
		<div className={cc(['flex flex-col', containerClassName])}>
			<label
				htmlFor={name as string}
				className={cc([
					`mb-2 font-headline text-base tracking-widest text-skin-input-label ${id}`,
					{
						'sr-only': hideLabel,
						truncate: truncateLabel,
					},
					labelClassName,
				])}
				{...restLabelProps}
			>
				{label}
				{label && required && (
					<>
						<span aria-hidden>*</span>
						<span className={cc(['sr-only'])}> Required</span>
					</>
				)}
			</label>

			<div className="relative flex items-center">
				{icon && (
					<div className="absolute left-4 flex items-center">
						<Icon
							name={icon}
							className="h-6 w-6"
							stroke={
								isFocused
									? iconOptions.focus?.stroke
									: iconOptions.static?.stroke
							}
							color={
								isFocused ? iconOptions.focus?.color : iconOptions.static?.color
							}
						/>
					</div>
				)}

				{prefix && (
					<div className="absolute left-4 flex items-center">
						<span className="text-skin-input-placeholder">{prefix}</span>
					</div>
				)}
				<input
					className={cc([
						'flex h-14 w-full items-center rounded-skin-input border border-solid border-skin-input-base bg-skin-input-base font-headline text-base text-skin-input-base placeholder-neutral-400 placeholder-shown:text-ellipsis hover:border-skin-input-hover hover:bg-skin-input-hover hover:bg-opacity-5 focus:border-skin-input-focus focus:bg-skin-input-focus focus:shadow-none focus:outline-none focus:ring-transparent disabled:border-neutral-300 disabled:bg-transparent disabled:text-neutral-500',
						prefix ? 'pl-10' : 'px-4',
						'input',
						`${id}`,
						{
							'pl-14': icon,
							'border-skin-input-error bg-skin-input-error pr-14 hover:border-skin-input-error hover:bg-skin-input-error focus:border-skin-input-error focus:bg-skin-input-error':
								!isPassword && errorMessage,
							'pr-14 tracking-[2.5px] placeholder-shown:tracking-normal': isPassword,
							'border-skin-input-error bg-skin-input-error pr-20 hover:border-skin-input-error hover:bg-skin-input-error focus:border-skin-input-error focus:bg-skin-input-error':
								isPassword && errorMessage,
						},
						className,
					])}
					ref={(e) => {
						if (localRef) {
							localRef(e);
							internalRef.current = e;
						}
					}}
					name={name}
					type={inputType}
					{...restInputProps}
					onFocus={toggleIsFocused}
					onChange={inputProps?.locale ? formatMaskNumber : inputProps.onChange}
					value={inputProps?.locale ? formattedValue : inputValue}
					data-required={required}
				/>

				{showRightIcons && (
					<div
						className={cc([
							'absolute flex items-center gap-2',
							{
								'right-4': !onRemove,
								'right-10': onRemove,
							},
						])}
					>
						{isPassword && (
							<button
								type="button"
								className="flex items-center"
								onClick={togglePasswordVisibility}
								aria-label="Toggle Password Visibility"
							>
								<Icon
									name={showPassword ? 'eye' : 'eye-closed'}
									className={cc([
										'h-6 w-6 fill-transparent stroke-current text-neutral-400',
										{
											'text-skin-accent': isFocused && !errorMessage,
										},
									])}
								/>
							</button>
						)}

						{errorMessage && !trailingAddOnText && (
							<Icon
								name="error"
								className="ml-1 h-6 w-6 fill-current text-danger"
							/>
						)}

						{postfix && (
							<div
								className={cc([
									'text-input-postfix-placeholder flex items-center',
								])}
							>
								<span className="text-skin-input-placeholder">{postfix}</span>
							</div>
						)}
					</div>
				)}

				{onRemove && (
					<div className="ml-2 cursor-pointer" onClick={onRemove}>
						<XIcon className="button-remove-tag h-6 w-6 stroke-current text-skin-link-base hover:text-skin-link-base/50" />
					</div>
				)}

				{trailingAddOnText && (
					<span
						className={cc([
							'inline-flex h-full items-center border border-l-0 px-3 font-bold text-gray-600 sm:text-sm',
							{
								'border-skin-input-error bg-skin-input-error': errorMessage,
								'border-neutral-300 bg-neutral-200': !errorMessage,
							},
						])}
					>
						{trailingAddOnText}

						{errorMessage && (
							<Icon
								name="error"
								className="ml-1 h-6 w-6 fill-current text-danger"
							/>
						)}
					</span>
				)}
			</div>

			{errorMessage && (
				<TextParagraph
					className={cc([
						'mt-1 font-body text-base text-skin-input-error-message',
						`input-error-message input-error-message-${name} input-error-message-${id}`,
					])}
					textParagraphSize={TextParagraphSize.p5}
				>
					{errorMessage}
				</TextParagraph>
			)}
		</div>
	);
};

export const PhoneInput = forwardRef(
	(props: PhoneInputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
		const {
			label,
			errorMessage,
			defaultCountry,
			dropdownHeight,
			countriesSortOrder,
			onCountryChange,
			setFocus,
			setValue,
			inputProps,
			className: containerClassName,
		} = props;

		const {
			className,
			name,
			required,
			onChange,
			...restInputProps
		} = inputProps;

		const containerRef = useRef(null);

		const [countries, setCountries] = useState([]);
		const [countryCode, setCountryCode] = useState<CountryCode>(
			defaultCountry as CountryCode,
		);
		const [showDropdown, setShowDropdown] = useState(false);

		const onCustomChange = (e) => {
			setShowDropdown(false);

			const phoneNumberObj = parsePhoneNumber(e.target.value, countryCode);
			const formattedPhoneNumber =
				phoneNumberObj?.formatInternational() ?? e.target.value;

			if (phoneNumberObj) {
				if (phoneNumberObj.country && phoneNumberObj.country !== countryCode) {
					setCountryCode(phoneNumberObj.country);
				}
			}

			const updatedEvent = e;
			e.target.value = formattedPhoneNumber;

			onChange(updatedEvent);
		};

		const onCustomCountryChange = (countryCode: CountryCode) => {
			setCountryCode(countryCode);
			onCountryChange && onCountryChange(countryCode);

			const countryCallingCode = getCountryCallingCode(countryCode);

			setValue && setValue(name, `+${countryCallingCode}`);
			setFocus && setFocus(name);
			setShowDropdown(false);
		};

		const toggleDropdown = () => {
			setShowDropdown(!showDropdown);
		};

		useEffect(() => {
			(async () => {
				if (countriesSortOrder) {
					setCountries(
						sortElementsByList(COUNTRIES, countriesSortOrder, 'code'),
					);
				} else {
					setCountries(COUNTRIES);
				}
			})();
		}, []);

		useClickOutside(containerRef, showDropdown, () => setShowDropdown(false));

		const showCurrentFlag = countryCode.toLowerCase() in Flags;
		const dropdownStyle = dropdownHeight ? { height: dropdownHeight } : null;

		return (
			<div className={cc(['flex flex-col', containerClassName])}>
				<label
					htmlFor={name as string}
					className="mb-2 font-headline text-base tracking-widest text-skin-input-label"
				>
					{label}
					{required && (
						<>
							<span aria-hidden>*</span>
							<span className={cc(['sr-only'])}> Required</span>
						</>
					)}
				</label>

				<div className="relative flex items-center">
					<div className="relative flex w-full items-center" ref={containerRef}>
						<div className="absolute left-2 flex items-center after:ml-4 after:block after:h-8 after:w-px after:bg-neutral-300">
							<button
								className="flex w-12 items-center"
								onClick={toggleDropdown}
								type="button"
							>
								<Icon
									name="arrowdown"
									className={cc([
										'mr-2 h-4 w-4 fill-current text-neutral-500',
										{
											'rotate-180': showDropdown,
										},
									])}
								/>

								{showCurrentFlag && (
									<Flag name={Flags[countryCode.toLowerCase()]} />
								)}
							</button>
						</div>

						{showDropdown && countries.length && (
							<div
								className="absolute top-full z-10 h-80 w-full cursor-pointer overflow-auto bg-white py-2 shadow"
								style={dropdownStyle}
							>
								{countries.map((country) => {
									return (
										<button
											className={cc([
												'flex w-full items-center py-2 pr-4 pl-8 text-neutral-500 hover:bg-black hover:bg-opacity-5',
												{
													'pl-[72px]': !country.showFlag,
												},
											])}
											onClick={() => onCustomCountryChange(country.code)}
											key={country.name}
										>
											{country.showFlag && (
												<Flag
													name={Flags[country.code.toLowerCase()]}
													className="mr-4"
												/>
											)}

											<span className="mr-4 w-[34px] text-left font-headline text-tiny">
												+{country.callingCode}
											</span>

											<span className="truncate font-headline text-tiny">
												{country.name}
											</span>
										</button>
									);
								})}
							</div>
						)}

						<input
							className={cc([
								'flex h-14 w-full items-center rounded-skin-input border border-solid border-skin-input-base bg-skin-input-base pt-[16px] pr-[15px] pb-[18px] pl-[87px] font-headline text-base text-skin-input-base placeholder-neutral-400 placeholder-shown:text-ellipsis hover:border-skin-input-hover hover:bg-skin-input-hover hover:bg-opacity-5 focus:border-skin-input-focus focus:bg-skin-input-focus focus:shadow-none focus:outline-none focus:ring-transparent disabled:border-neutral-300 disabled:bg-transparent disabled:text-neutral-500',
								{
									'border-skin-input-error bg-skin-input-error pr-14 hover:border-skin-input-error hover:bg-skin-input-error focus:border-skin-input-error focus:bg-skin-input-error': errorMessage,
								},
								className,
							])}
							ref={ref}
							name={name}
							onChange={onCustomChange}
							{...restInputProps}
							type="tel"
							data-required={required}
						/>

						{errorMessage && (
							<div className="absolute right-4 flex items-center">
								<Icon
									name="error"
									className="ml-1 h-6 w-6 fill-current text-danger"
								/>
							</div>
						)}
					</div>
				</div>

				{errorMessage && (
					<p
						className={cc([
							'input-error-message',
							`input-error-message-${name}`,
							'mt-1 font-body text-base text-skin-input-error-message',
						])}
					>
						{errorMessage}
					</p>
				)}
			</div>
		);
	},
);

export const TextAreaInput = forwardRef(
	(
		props: TextAreaInputProps,
		ref: React.MutableRefObject<HTMLTextAreaElement>,
	) => {
		const {
			label,
			truncateLabel = true,
			errorMessage,
			inputProps,
			icon,
			iconOptions,
			className: containerClassName,
		} = props;

		const { className, name, required, ...restInputProps } = inputProps;

		const [isFocused, setIsFocused] = useState(false);

		const showRightIcons = errorMessage;

		return (
			<div className={cc(['flex flex-col', containerClassName])}>
				<label
					htmlFor={name as string}
					className={cc([
						'mb-2 font-headline text-base tracking-widest text-skin-input-label',
						{ truncate: truncateLabel },
					])}
				>
					{label}
					{label && required && (
						<>
							<span aria-hidden>*</span>
							<span className={cc(['sr-only'])}> Required</span>
						</>
					)}
				</label>

				<div className="relative flex items-center">
					{icon && (
						<div className="absolute left-4 flex items-center">
							<Icon
								name={icon}
								className="h-6 w-6"
								stroke={
									isFocused
										? iconOptions.focus?.stroke
										: iconOptions.static?.stroke
								}
								color={
									isFocused
										? iconOptions.focus?.color
										: iconOptions.static?.color
								}
							/>
						</div>
					)}
					<textarea
						className={cc([
							'flex h-32 w-full resize-none items-center rounded-skin-input border border-solid border-skin-input-base bg-skin-input-base font-headline text-base text-skin-input-base placeholder-neutral-400 placeholder-shown:text-ellipsis hover:border-skin-input-hover hover:bg-skin-input-hover hover:bg-opacity-5 focus:border-skin-input-focus focus:bg-skin-input-focus focus:shadow-none focus:outline-none focus:ring-transparent disabled:border-neutral-300 disabled:bg-transparent disabled:text-neutral-500',
							{
								'pl-14': icon,
								'border-skin-input-error bg-skin-input-error pr-14 hover:border-skin-input-error hover:bg-skin-input-error focus:border-skin-input-error focus:bg-skin-input-error': errorMessage,
							},
							className,
						])}
						ref={ref}
						name={name}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						{...(restInputProps as React.ComponentProps<'textarea'>)}
						data-required={required}
					/>

					{showRightIcons && (
						<div className="absolute right-4 flex items-center">
							{errorMessage && (
								<Icon
									name="error"
									className="ml-1 h-6 w-6 fill-current text-danger"
								/>
							)}
						</div>
					)}
				</div>

				{errorMessage && (
					<p
						className={cc([
							'mt-1 font-body text-base text-skin-input-error-message',
							`input-error-message-${name}`,
						])}
					>
						{errorMessage}
					</p>
				)}
			</div>
		);
	},
);
